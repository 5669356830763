import { Component, OnInit, Input } from '@angular/core';
import {CountrycheckService} from '../../services/countrycheck/countrycheck.service';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-my-cards',
  templateUrl: './my-cards.component.html',
  styleUrls: ['./my-cards.component.scss'],
})
export class MyCardsComponent implements OnInit {
  @Input() cards: any;
  @Input() hasCards: boolean;
  @Input() cardType: string;
  @Input() sortAttribute: string;
  @Input() sortingType: string;


  AscFilter = 'run_at:ASC';
  DescFilter = 'run_at:DESC';
  text: string;
  constructor(
    public cCheck: CountrycheckService,
    private router: Router
  ) { }

  ngOnInit() {}


  /**
   * Navigate to the detail page for this item.
   */
  async openCard(card: any, isLiveCard = false) {
    //internet connection check
    const conn: any = await this.cCheck.checkInternet();
    if (!conn.result) {
      return false;
    }

    if(isLiveCard){
      // this.firebaseStats.sendEvent('live_game_opened', {})
    }

    const navigationExtras: NavigationExtras = {
      queryParams: {
          gameId: JSON.stringify({gameId: card.game.id})
      }
    };
    let url = '/tabs/tabs/mycards/livecard';
    switch (card.game.type) {
      case 'f1':
        url = '/tabs/tabs/mycards/livecardf1';
        break;
      case 'bingoals':
        url = '/tabs/tabs/mycards/bingoal';
        break;
      default:

    }

    this.router.navigate([url],navigationExtras);
  }
}
