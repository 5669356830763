import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reformatDates'
})
export class ReformatDatesPipe implements PipeTransform {

  transform(value: string, ...args) {
    const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const firstStrs = value.split(' ');
    const dateStrs: any = firstStrs[0].split('-');
    const finalStr = months[ Number(dateStrs[1]-1) ] +', '+dateStrs[2]+', '+dateStrs[0];
    return finalStr;
  }

}
