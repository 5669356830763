import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import{DatePipe} from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-bingoal-claimed',
  templateUrl: './bingoal-claimed.component.html',
  styleUrls: ['./bingoal-claimed.component.scss'],
})
export class BingoalClaimedComponent implements OnInit {
  @Input() date;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();
  moment = moment;
  constructor(
    private router: Router,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit() {}


  close() {
    this.closeDialog.emit();
  }

  viewCards(){
    this.closeDialog.emit();
    const navigationExtras: NavigationExtras = {
      queryParams: {showScheduled: true}
    };
    this.router.navigate(['/tabs/tabs/mycards'], navigationExtras);
  }

  isToday(date) {
    const compareDate = moment.utc(date).local();
    return compareDate.isSame(moment(), 'day');
  }

  prepareDate(date) {
    if(date){
      const dDate = this.isToday(date) ? 'Today' : this.datePipe.transform(date, 'd MMMM yyyy');
      // @ts-ignore
      const dTime = this.datePipe.transform(moment.utc(date), 'h:mmaa');
      return dDate+' - '+dTime;
    }
  }
}
