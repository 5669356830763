import { Component, OnInit, Input } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
@Component({
  selector: 'app-gambling-links',
  templateUrl: './gambling-links.component.html',
  styleUrls: ['./gambling-links.component.scss'],
})
export class GamblingLinksComponent implements OnInit {
  @Input() theme;
  config = ENV;
  constructor() { }

  ngOnInit() {}

}
