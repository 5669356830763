import { Component, OnInit, Input } from '@angular/core';
import {PrepareWinnersPipe} from '../../pipes/prepare-winners/prepare-winners.pipe';

@Component({
  selector: 'app-active-lines',
  templateUrl: './active-lines.component.html',
  styleUrls: ['./active-lines.component.scss'],
})
export class ActiveLinesComponent implements OnInit {
  @Input() game_win_statuses: any;
  @Input() userData: any;
  @Input() game: any;
  constructor() { }

  ngOnInit() {}

  checkWinner(win, userId) {
    const meWinner = win.winners.find(user => user.id === userId);
    if(win.happened && meWinner && !win.is_any_time_full_house ){
      return 'win_happened_with_me';
    }else if (win.happened && !meWinner && !win.is_any_time_full_house) {
      return 'win_happened_not_me';
    }else if (win.happened && meWinner && win.is_any_time_full_house) {
      return 'win_happened_with_me_anytime';
    }else if(win.happened && !meWinner && win.is_any_time_full_house) {
      return 'win_happened_not_me_anytime';
    } else if(!win.happened && !meWinner && win.is_any_time_full_house){
      return 'not_happened_anytime';
    }
    return '';
  }

  checkLinesText(key) {
    const countKey = key+1;
    if(countKey === 3 || countKey === 4){
      return 'Full house';
    }
    return countKey + ' line' + (countKey > 1 ? 's' : '');
  }

}
