import { Component, OnInit, Input } from '@angular/core';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-how-to-win-dialog',
  templateUrl: './how-to-win-dialog.component.html',
  styleUrls: ['./how-to-win-dialog.component.scss'],
})
export class HowToWinDialogComponent implements OnInit {
  @Input() gameId;
  @Input() gameType;
  // slides: Slide[];
  showSkip = true;
  dir: string = 'ltr';
  showPopup: boolean = true;
  constructor(
    public platform: Platform
  ) {
    // this.dir = platform.dir();
  }

  ngOnInit() {}

  onSlideChangeStart(slider) {
    this.showSkip = !slider.isEnd();
  }

  closeGameOverDialog(){
    // this.navCtrl.push('LiveCardPage', { gameId: this.gameId });
    this.showPopup = false;
  }

}
