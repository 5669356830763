import { Component, OnInit, Input } from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {Storage} from '@ionic/storage-angular';
import {Events} from '../../services/events/events.service';

@Component({
  selector: 'app-reality-check',
  templateUrl: './reality-check.component.html',
  styleUrls: ['./reality-check.component.scss'],
})
export class RealityCheckComponent implements OnInit {
  @Input() pageName: string;
  showDialog = false;
  noteToSelf: string = '';
  constructor(
    private router: Router,
    private storage: Storage,
    public events: Events,
  ) {
    events.subscribe('realitycheck:changed', (noteToSelf) => {
      console.log('3434xx this realitycheck:changed: ', this.pageName);
      // console.log('3434xx Active realitycheck:changed: ', this.app.getActiveNav().getActive().name);

      console.log('this.router.url', this.router.url.split('?')[0], this.pageName);
      if (this.router.url.split('?')[0] === this.pageName) {
        this.showDialog = true;
        this.noteToSelf = noteToSelf;
        console.log('3434xx realitycheck:changed: ', this.pageName);
      }
    });
  }

  ngOnInit() {}

  goto(page: string) {
    this.router.navigate([page]);
  }

  gotoAccounts(){
    // this.navCtrl.push(page);
    this.router.navigate(['Accounts']);
  }

  clearUserSessionToken(){
    this.storage.remove('userSessionToken');
    this.router.navigate(['welcome']);
  }

  closeDialog(){
    this.showDialog = false;
  }

}
