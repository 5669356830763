import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviation'
})
export class AbbreviationPipe implements PipeTransform {

  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, ...args) {
    return ((value.split(' ')).map((word) => word[0])).join('');
  }

}
