import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';


const win: any = window;
@Component({
  selector: 'app-you-won-dialog',
  templateUrl: './you-won-dialog.component.html',
  styleUrls: ['./you-won-dialog.component.scss'],
})
export class YouWonDialogComponent implements OnInit {
  @Input() winData: any;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();
  spritesPath = '';
  localSprites;


  constructor(
    private router: Router
  ) {
    this.localSprites = JSON.parse(localStorage.getItem('spritesSounds'));
  }

  ngOnInit() {}

  setSpritePosition(spriteIndex) {
    // console.log('cardEvent', cardEvent);
    // console.log('this.spritesPath', this.spritesPath);
    // console.log('cardEvent', cardEvent);
    // console.log('this.game', this.game.game_template);


    const sprId = this.winData.game.game_template.win_sprite_sheet_id;
    // const image: any =  '';

    const locSpr = this.getLocalSpriteData(sprId);
    // image = image+'.'+this.SprSync.getExtensionFromString(locSpr.url);


    const row = Math.floor(spriteIndex/10);
    const yPos = (row * locSpr.height);
    const xPos = (((spriteIndex - (row * 10)) * locSpr.width));

    const pos = {
      'background-image': 'url(' + locSpr.url + ')',
      'background-position': '-' + xPos + 'px -' + yPos + 'px'
    };
    // console.log('index, row, yPos, xPos', index, row, yPos, xPos)
    return pos;
  }

  getLocalSpriteData(id) {
    return this.localSprites.sprites.find(x => x.id === id);
  }


  close() {
    this.closeDialog.emit();
  }


  openAccount() {
    // this.app.getRootNav().getActiveChildNav().select(2)
    this.router.navigate(['/tabs/tabs/myaccount']);
    this.closeDialog.emit();
  }

}
