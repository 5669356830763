export const environment = {
  isProdConfig: false,
  serverURl: 'https://qadev.matchbingo.co.uk/',
  pusherKey: '39b77ad26c033608dc4c',
  gamblingLicenseURL: 'https://www.gamblingcommission.gov.uk/public-register/business/detail/51467',
  proveMyAge: {
    URL: 'https://sandbox.provemyage.com/',
    bid: '5e4b0c10-a6e1-4ca8-8cf8-a44f3e6f9f85'
  },
  onesignal: {
    appId:  'fd71ad8b-182b-4fc2-ae90-9786dfc64073',
    googleProjectNumber: '363888496890'
  },
  countryCheckDevMode: true,
  showDebugSettings: true,
  gMapsApiKey: 'AIzaSyDSwCdumIbkqrLAxM6TNoXOrkUj08QBuQE',
  minDeposit: 10,
  minWithdraw: 10,
  stopBackgroundMode: 5400000,
  supportEmail: 'support@matchbingo.co.uk',
  gamstopURL: 'https://www.gamstop.co.uk',
  registrationUrl:'https://qadev.b2b.matchbingo.co.uk/sign-up/'
};
