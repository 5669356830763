import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input('appTooltip') tooltipTitle: string;
  @Input() placement: string;
  @Input() delay: any;
  @Input() tooltipId: any;
  @Input() isHeaderTooltip: any;
  @Input() winners: any;
  @Input() userId: any;
  @Input() isHappened: any;

  tooltip: HTMLElement;
  offset = 20;
  constructor(private el: ElementRef, private renderer: Renderer2) {
    // window.addEventListener('scroll', () => {
    //   if(document.querySelectorAll('.ng-tooltip').length) {
    //     this.hide();
    //   }
    // }, true);

    const self = this;
    window.addEventListener('click',  (evt: any) => {
      // console.log('evt', evt.target.className, evt.target.className == 'tooltip-overlay', (evt.target.className).indexOf('ng-tooltip'));
      if (evt.target.className === 'tooltip-overlay' || (evt.target.className).indexOf('ng-tooltip') !== -1) {
        self.hide();
      }
    }, false);
  }

  @HostListener('click') onClick(e) {
    // console.log('click')
    if(this.tooltipTitle == null) {return false; }

    if(document.querySelector('.tooltipId'+this.tooltipId)) {
      this.hide();
    }else {
      this.hide();
      this.show();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    // window.setTimeout(() => {
    this.tooltip = null;
    if(document.querySelectorAll('.tooltip-overlay').length) {
      document.querySelector('.tooltip-overlay').remove();
    }

    document.querySelectorAll('.ng-tooltip').forEach((a) =>{
      a.remove();
    });

    // }, this.delay);
  }

  create() {
    this.tooltip = this.renderer.createElement('span');
    const overlay = this.renderer.createElement('div');
    const titleDiv = this.renderer.createElement('div');
    this.renderer.addClass(titleDiv, 'tooltip-title');

    if(this.isHeaderTooltip) {
      if(this.isHappened){
        const winnerDiv = this.renderer.createElement('div');
        this.renderer.addClass(winnerDiv, 'tooltip-winner');
        this.renderer.appendChild(titleDiv, winnerDiv);
        this.renderer.appendChild(
          winnerDiv,
          this.renderer.createText('Claimed by: ')
        );
        const haveMyUser = this.winners.filter((el) => el.id === this.userId );
        if(this.winners.length > 10) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const winnerDiv = this.renderer.createElement('div');
          this.renderer.addClass(winnerDiv, 'tooltip-winner');
          const title = ((haveMyUser.length) ? haveMyUser[0].username + ' and ' : '') + this.winners.length + ' others';
          this.renderer.appendChild(winnerDiv, this.renderer.createText(title));
          if(haveMyUser.length) {
            this.renderer.addClass(winnerDiv, 'my-user');
          }
          this.renderer.appendChild(titleDiv, winnerDiv);
        }else {

          this.winners.forEach(value => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const winnerDiv = this.renderer.createElement('div');
            this.renderer.addClass(winnerDiv, 'tooltip-winner');
            if(value.id === this.userId) {
              this.renderer.addClass(winnerDiv, 'my-user');
            }
            this.renderer.appendChild(
              winnerDiv,
              this.renderer.createText(value.username)
            );
            this.renderer.appendChild(titleDiv, winnerDiv);
          });
        }
      }else {
        this.renderer.appendChild(titleDiv, this.renderer.createText(this.tooltipTitle));
      }
    }else {
      this.renderer.appendChild(titleDiv, this.renderer.createText(this.tooltipTitle));
    }


    this.renderer.appendChild(
      this.tooltip,
      titleDiv // textNode
    );


    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.appendChild(document.body, overlay);
    this.renderer.addClass(overlay, 'tooltip-overlay');
    // this.renderer.appendChild(this.el.nativeElement, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, 'tooltipId'+this.tooltipId);
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    // delay 설정
    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  setPosition() {

    const hostPos = this.el.nativeElement.getBoundingClientRect();


    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top; let left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

}
