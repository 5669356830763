import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastController, AlertController} from '@ionic/angular';
import {ApiService, Events } from '../services';
import {DomSanitizer} from '@angular/platform-browser';
import {environment as ENV} from '../../../environments/environment';
import {Echo} from 'laravel-echo-ionic';
import {Router} from '@angular/router';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  userSessionToken: any;
  echo: any;
  userId = 0;
  constructor(
    public http: HttpClient,
    private storage: Storage,
    public api: ApiService,
    public toastController: ToastController,
    public events: Events,
    public alertCtrl: AlertController,
    private _sanitizer: DomSanitizer,
    private router: Router
  ) {
    console.log('PusherProvider loaded')
  }

  initPusher() {
    // this.echo
    this.storage.get('userSessionToken').then((val) => {
      this.userSessionToken = val;
      this.api.userDetails(this.userSessionToken).subscribe(userDetail => {
        this.userId = userDetail.data.user.id;
        const pusherConfig = {
          broadcaster: 'pusher',
          key: ENV.pusherKey,
          cluster: 'eu',
          forceTLS: true,
          authEndpoint : ENV.serverURl+'broadcasting/auth',
          auth: {
            headers: {
              Authorization: 'Bearer ' + this.userSessionToken
            }
          }
        };
        this.echo = new Echo(pusherConfig);
        this.setupUserEnterLeave();
        this.listenDepositWithdrawalEvents();
        this.listenBalanceEvents();
        this.listenSubscriptionUnsubscribeEvent();
      });
    });
  }

  deInitChannels() {
    this.echo.disconnect();
  }

  leaveFromChannel(channel) {
    this.echo.leave(channel);
  }

  setupUserEnterLeave() {
    // this.echo.join('app-presence')
    //   .here((users) => {
    //     // console.log('here users', users);
    //   })
    //   .joining((user) => {
    //     // console.log('joining users', user);
    //   })
    //   .leaving((user) => {
    //     // console.log('leaving users', user);
    //   });
  }


  listenSubscriptionUnsubscribeEvent() {
    this.echo.private('channel-user.'+this.userId).listen('UserUnsubscribed', UserUnsubscribedEvent => {
      console.log('UserUnsubscribed', UserUnsubscribedEvent);
      // this.app.getRootNav().getActiveChildNav().select(2);
    });
  }

  listenDepositWithdrawalEvents() {
    // console.log('listenDepositWithdrawalEvents');
    this.echo.private('account-transactions.'+this.userId).listen('TransactionStatusChanged', transactionEvent => {
      console.log('transactionsEvents', transactionEvent);
      this.processTransactionInfo(transactionEvent);
    });
  }

  listenBalanceEvents() {
    this.echo.private('account-balance.'+this.userId).listen('AccountBalanceChanged', event => {
      console.log('listenBalanceEvents', event)
      this.storage.set('balance', event.balance).then((res) => {

        // console.log('res', res);
      });

      this.storage.set('win_balance', event.redeemable_balance).then((res) => {
        // console.log('res', res);
      });

      this.events.publish('balance:refresh');

      if(event.subscription_info !== null) {
        // eslint-disable-next-line no-underscore-dangle
        let text: any = this._sanitizer.bypassSecurityTrustHtml(event.subscription_info.text);
        text = text.changingThisBreaksApplicationSecurity;
        this.showAlert(event.subscription_info.title, '<br>' + text.replace(new RegExp('\\n', 'g'), '<br />'), 'AccountsPage');
      }
    });
  }


  loadBalance(userSessionToken){
    let bal = 0;
    let win_balance = 0;
    this.api.getBalance(userSessionToken).subscribe(balance => {
      // console.log('balance', balance);
      const newBal = balance.data;
      // balance: "1363.73"
      // redeemable_balance: "20.00"
      // console.log("£!£!"+JSON.stringify(balance));
      if(balance.hasOwnProperty('apiStatus')){
        if(balance.apiStatus === 'FAIL') {
          bal = 0;
        } else {
          if(balance.apiStatus === 'OK') {
            bal = newBal.balance;
            win_balance = newBal.redeemable_balance
          }
        }
      }
      this.storage.set('balance', bal).then((res) => {
        // console.log('actual balance', res);
      });
      this.storage.set('win_balance', win_balance).then((res) => {
        // console.log('actual balance', res);
      });

    });
  }


  processTransactionInfo(event) {
    if (event.type === 'deposit') {
      this.events.publish('deposit:refresh');
    } else {
      this.events.publish('withdrawal:refresh');
    }

    const messages = {
      deposit: {
        complete: {
          title: 'Success',
          subtitle: 'You have made a new deposit and your funds are now available for purchasing cards.'
        },
        failed: {
          title: 'There was a problem',
          subtitle: 'Please contact '+ENV.supportEmail
        }
      },
      withdrawal: {
        complete: {
          title: 'Success',
          subtitle: 'You have made a new withdrawal.'
        },
        pending2credit: {
          title: 'Success',
          subtitle: 'Admin will review your request.'
        },
        failed: {
          title: 'There was a problem',
          subtitle: 'Please contact '+ENV.supportEmail
        }
      }
    };

    switch (event.status) {
      case 'complete':
        this.showAlert(messages[event.type][event.status].title, messages[event.type][event.status].subtitle, event.type);
        break;
      case 'pending2credit':
        this.showAlert(messages[event.type][event.status].title, messages[event.type][event.status].subtitle, event.type);
        break;
      case 'failed':
        this.showAlert(messages[event.type]['failed'].title, messages[event.type]['failed'].subtitle, event.type);
        break;
      case 'escalate':
        this.showAlert(messages[event.type]['failed'].title, messages[event.type]['failed'].subtitle, event.type);
        break;
      case 'rejected':
        this.showAlert(messages[event.type]['failed'].title, messages[event.type]['failed'].subtitle, event.type);
        break;
    }
  }

  async showAlert(title, subtitle, pageToRedirect = '') {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: subtitle,
      buttons: [{
        text: 'OK', handler: () => {
          if (pageToRedirect === 'AccountsPage') {
            this.router.navigate(['/tabs/tabs/myaccount']);
            // this.events.publish('pageRedirect:page', {page: pageToRedirect});
          }
          // let navTransition = alertMessage.dismiss();
          // if(eventType == 'complete') {
          //   navTransition.then(() => {
          //     // const tabs = this.navCtrl.parent;
          //     // tabs.select(3).then(() => tabs.getSelected().setRoot("AccountsPage")).then(() => this.navCtrl.popToRoot());
          //   });
          // }
          return true;
        }
      }]
    });

    await alert.present();
  }
}
