import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-winner-dialog',
  templateUrl: './winner-dialog.component.html',
  styleUrls: ['./winner-dialog.component.scss'],
})
export class WinnerDialogComponent implements OnInit {
  @Input() winners: any;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();
  @Input() userId: number;
  constructor() { }

  ngOnInit() {}

  close() {
    this.closeDialog.emit();
  }
}
