import {Component, Input, Output, EventEmitter, NgZone} from '@angular/core';
import {NavController} from '@ionic/angular';
import {NavigationExtras, Router} from '@angular/router';
import { Events } from '../../services/services';


@Component({
  selector: 'app-info-window-dialog',
  templateUrl: './info-window-dialog.component.html',
  styleUrls: ['./info-window-dialog.component.scss'],
})
export class InfoWindowDialogComponent  {
  @Input() showInfow;
  @Input() title;
  @Input() descr;
  @Input() button;
  @Input() showSecond;
  @Input() secondButtonText;
  @Input() secondButtonUrl;
  @Output() onClose = new EventEmitter();
  showModal: boolean = true;
  constructor(
              public zone: NgZone,
              public navCtrl: NavController,
              private router: Router,
              public events: Events,
  ) {

    events.subscribe('showInfoDialog', () => {
      console.log('showInfoDialog');
      // this.zone.run(() => {
      //
      // })
      this.showInfow = true;
    });
  }


  goTo(page: string) {
    this.router.navigate([page]);
  }

  closeWindow(){
    this.onClose.emit('dialog closed');
    this.showInfow = false;
  }


}
