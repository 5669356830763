import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {ApiService, Events } from '../../services/services';
import { Storage } from '@ionic/storage-angular';
import {Router} from "@angular/router";

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
//@Input() forceLoad: boolean = false;
  @Output() sentBalance: EventEmitter<any>;
  @Input() forceLoad: any;
  @Input() isPrizeBalance: boolean;
  @Input() isAccountTab: boolean;
  @Input() showDecimal: boolean;
  userSessionToken: any;
  balance: any = 0;
  balanceLoading: boolean = true;
  forceLoadInterval: any;
  constructor(
    public api: ApiService,
    private storage: Storage,
    public events: Events,
    private router: Router
  ) {
    // this.loadBalance('constructor');
    this.sentBalance = new EventEmitter<number>();
// watch for forceLoad
    // console.log("balance.ts forceLoad =:"+this.forceLoad);
    this.forceLoadInterval = setInterval(() => {
      // console.log("forceLoadInterval: "+this.forceLoad);
      if (this.forceLoad) {
        this.forceLoad = false;
        this.balanceLoading = true;
        this.loadBalance('interval');
      }
    }, 1000);

    events.subscribe('balance:refresh', (noteToSelf) => {
      this.loadBalance('subscribe');
    });
  }


  ngOnInit() {
  }

  openMyAccount() {
    this.router.navigate(['/tabs/tabs/myaccount']);
  }

  /**
   *
   */
  loadBalance(fromWhere){
    console.log('loadBalance from '+fromWhere, this.isPrizeBalance);
    if(this.isPrizeBalance){
      this.storage.get('win_balance').then((winBalance) => {
        this.balance = parseFloat(winBalance).toFixed(0);
        if(this.showDecimal) {
          this.balance = winBalance;
        }
        this.sentBalance.emit(this.balance);
        this.balanceLoading = false;
        // console.log("loadBalance this.isPrizeBalance");
      });
    }else {

      this.storage.get('balance').then((balance) => {
        console.log('balance', balance);
        this.balance = parseFloat(balance).toFixed(0);
        if(this.showDecimal) {
          this.balance = balance;
        }
        this.sentBalance.emit(this.balance);
        this.balanceLoading = false;
        console.log('loadBalance this.isPrizeBalance else', this.balance);
      });
    }

    // this.storage.get('userSessionToken').then((userSessionToken) => {
    // this.userSessionToken = userSessionToken;
    // this.items.getBalance(userSessionToken).subscribe(balance => {
    //   console.log("£!£!"+JSON.stringify(balance));
    //   if(balance.hasOwnProperty('apiStatus')){
    //     if(balance.apiStatus == "FAIL") {
    //       this.balance = 0;
    //     } else {
    //       if(balance.apiStatus == "SUCCESS") {
    //         this.balance = balance.value;
    //       }
    //     }
    //     this.sentBalance.emit(this.balance);
    //     this.balanceLoading = false;
    //   }
    // });
    // });
  }



}
