import { NgModule } from '@angular/core';
import {TooltipDirective} from './tooltip/tooltip.directive';

@NgModule({
	declarations: [
    TooltipDirective
  ],
	imports: [],
	exports: [
    TooltipDirective,
  ]
})
export class DirectivesModule {}
