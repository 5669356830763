import { Injectable } from '@angular/core';
import {environment as ENV} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Device } from '@awesome-cordova-plugins/device/ngx';

let httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '})
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  itemUrl: string = ENV.serverURl+'api';
  constructor(
    private http: HttpClient,
    private device: Device
  ) { }

  getLegalInformation(userSessionToken = '') {
    if(userSessionToken !== ''){
      httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    }
    const apiURL = this.itemUrl+'/v2/partners/legal';
    return this.http.get<any>(apiURL, httpOptions);
  }

  /*** check user email if our, partner or returned
   * @param email
   */
  signUpWhoisEmail(email) {
    return this.http.get<any>(this.itemUrl+'/v3/signup/whois/' + email, httpOptions);
  }

  /**
   * Check if country is UK
   */
  countryCheck(): Observable<any> {
    console.log('countryCheck: '+this.itemUrl+'/countrycheck/');
    return this.http.get<any>(this.itemUrl+'/countrycheck/', httpOptions);
  }

  /**
   * Geocode By coordinates
   */
  getGeocode(url): Observable<any> {
    return this.http.get<any>(url, httpOptions);
  }

  /**
   * User sign in
   * @param postBody
   */
  userSingIn(postBody) {
    // const httpOptions = { headers: new HttpHeaders({
    //     // eslint-disable-next-line @typescript-eslint/naming-convention
    //     'Content-Type': 'application/json',
    //     // eslint-disable-next-line @typescript-eslint/naming-convention
    //     'X-HEADER-DEVICE-ID': this.device.uuid
    //   }) };
    return this.http.post<any>(this.itemUrl+'/v2/auth/login', postBody, httpOptions);
  }

  /**
   * Check if user blocked or no
   */
  checkUserExclusion(userSessionToken, area): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<any>(this.itemUrl+'/exclusions/check/'+area, httpOptions);
  }

  remindUser(post: any): Observable<any> {
    return this.http.post<any>(this.itemUrl+'/reset', post, httpOptions);
  }

  /**
   * Get Partners list
   */
  getPartnersList(): Observable<any[]> {
    const apiURL = this.itemUrl+'/v2/partners';
    return this.http.get<any>(apiURL, httpOptions);
  }

  /**
   *
   * @param userSessionToken
   */
  userDetails(userSessionToken: number): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<any>(this.itemUrl+'/v2/users/me', httpOptions);
  }


  /**
   *
   * @param userSessionToken
   */
  getBalance(userSessionToken: number): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<any>(this.itemUrl+'/v2/transactions/balances', httpOptions);
  }

  /**
   * get games avaialble for purchase
   * @param userSessionToken
   */
  getGames(userSessionToken: string, gameType): Observable<[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<[]>(this.itemUrl+'/v3/games/by-template/'+gameType, httpOptions);
  }

  /**
   * Get ads
   */
  getAds(userSessionToken): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<any>(this.itemUrl+'/ads', httpOptions);
  }



  /**
   * Exclude Ads
   */
  excludeAds(userSessionToken: string, adId: number): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const post = {};
    return this.http.post<any>(this.itemUrl+'/ads/exclude/'+adId, post, httpOptions);
  }

  // get a list cardCounts (userID, GameID, count)
  getCardCounts(userSessionToken: string, gamesMode = 'live', page = 1, per_page = 25, sorting = 'desc'): Observable<any[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    // console.log("userSessionToken: "+userSessionToken);
    // console.log("httpOptions: "+JSON.stringify(httpOptions));
    const apiURL = this.itemUrl+'/v3/cards/counts?games_mode='+gamesMode+'&page='+page+'&per_page='+per_page+'&game_order='+sorting;
    // console.log("apiURL: "+apiURL);
    return this.http.get<any[]>(apiURL, httpOptions);
  }

  /**
   * v2 of buy new cards
   * @param id
   * @param userSessionToken
   * @param cardCount
   */
  buyNewCard(id: number, userSessionToken: number, cardCount: number): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v2/games/'+id+'/cards';
    return this.http.post<any>(apiURL, {cards: cardCount}, httpOptions);
  }

  /**
   * get winners for game win or game over
   * @param userSessionToken
   * @param gameId
   * @param type
   */
  getWinnersData(userSessionToken, gameId, type = null) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    let typeData = '';
    if(type){
      typeData = '?type='+type;
    }
    return this.http.get<any>(this.itemUrl+'/v2/games/'+gameId+'/game-winners'+typeData, httpOptions);
  }

  // get game from id V2
  getGamev2(game: number, userSessionToken: string): Observable<any[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v2/games/getgame/'+game+'/';
    return this.http.get<any[]>(apiURL, httpOptions);
  }

  /**
   * Get Game Main Data.
   * @param gameId
   * @param userSessionToken
   */
  getCardsGameId(gameId: number , userSessionToken: string): Observable<any[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v3/games/'+gameId+'/';
    return this.http.get<any[]>(apiURL, httpOptions);
  }

  loadBlockPaginated(userSessionToken: string, gameId: number, page= ''){
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    let apiURL = this.itemUrl+'/allevents/loadblock-paginated/'+gameId;
    if(page !== '') {
      apiURL = page;
    }
    return this.http.get<any>(apiURL, httpOptions);
  }

  // allEvent feed loader
  loadAllEventBlock(userSessionToken: string, gameId: number, firstEvent: number, count: number): Observable<any> {
    //^^console.log("loadAllEventBlock userSessionToken: "+userSessionToken);
    //^^console.log("loadAllEventBlock: "+gameId+", first: "+firstEvent+", count: "+count);
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/allevents/loadblock/'+gameId+'/'+firstEvent+'/'+count;
    //^^console.log("$$$$$$$$$$$$ loadAllEventBlock: "+apiURL);
    return this.http.get<any>(apiURL, httpOptions);
  }

  // get a list of cards held by userSessionToken where game
  getCardByGame(userSessionToken: number, game: number): Observable<any[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/cards/getgamecards/'+game+'/';
    return this.http.get<any[]>(apiURL, httpOptions);
  }

  // get all cardId wins
  getCardsWins(gameId: number , userSessionToken: string): Observable<any[]> {
    // urgent
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/cardwins/allgamecardswins/'+gameId+'/';
    // //^^console.log("card wins apiURL :"+apiURL);
    return this.http.get<any[]>(apiURL, httpOptions);
  }

  // get all gameId wins
  getGameWins(gameId: number /*, userSessionToken: number*/): Observable<any[]> {
    const apiURL = this.itemUrl+'/gamewins/'+gameId; //+"/"+userSessionToken;
    // //^^console.log("game wins apiURL :"+apiURL);
    return this.http.get<any[]>(apiURL);
  }

  // get game from id
  getGame(game: number, userSessionToken: string): Observable<any[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/game/getgame/'+game+'/';
    return this.http.get<any[]>(apiURL, httpOptions);
  }

  // get live and scheduled games that this player has bought cards for
  getUserLiveAndPendingGames(userSessionToken: number): Observable<any[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    // console.log("echo debug - getUserLiveAndPendingGames userSessionToken: "+userSessionToken);
    return this.http.get<any[]>(this.itemUrl+'/getuserliveandpendinggames/', httpOptions);
  }

  /**
   * Logout user
   * @param userSessionToken
   */
  logout(userSessionToken: string): Observable<any> {
    httpOptions = { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+userSessionToken,
        'X-HEADER-DEVICE-ID': 'browser'
      }) };
    const apiURL = this.itemUrl+'/v2/auth/logout';
    return this.http.post<any>(apiURL, {}, httpOptions);
  }

  getKycVerify(userSessionToken) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v2/users/kyc/verify';
    return this.http.post<any>(apiURL, {}, httpOptions);
  }

  /**
   * Delete user account
   * @param userSessionToken
   */
  deleteAccount(userSessionToken) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v3/user';
    return this.http.delete<any>(apiURL, httpOptions);
  }

  /**
   * get Info about user subscription
   */
  getSubscriptionInfo(userSessionToken: string) {
    let httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+"/v2/users/subscription-info";
    return this.http.get<any>(apiURL, httpOptions);
  }

  /**
   * subscribe user
   */
  subscribeUser(userSessionToken: string) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v2/users/subscribe';
    return this.http.post<any>(apiURL, {}, httpOptions);
  }


  /**
   * subscribe user
   */
  unsubscribeUser(userSessionToken: string) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v2/users/unsubscribe';
    return this.http.post<any>(apiURL, {}, httpOptions);
  }


  /**
   * get Info about My Team
   */
  getMyTeamInfo(userSessionToken: string) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v2/my-team';
    return this.http.get<any>(apiURL, httpOptions);
  }


  /**
   * Update my team
   */
  changeMyTeam(userSessionToken: string, changeData) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v2/my-team';
    return this.http.post<any>(apiURL, changeData, httpOptions);
  }

  updateProfile(userSessionToken: string, data: any): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v2/users/me';
    return this.http.put<any>(apiURL, data, httpOptions);
  }

  getAccountransactionV3(userSessionToken: number, type: string, perPage): Observable<any[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<any>(this.itemUrl+'/v3/transactions?type='+type+'&per_page='+perPage, httpOptions);
  }

  /**
   * Get Banking Info
   * @param postBody
   */
  getBrandingInfo(userSessionToken) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<any>(this.itemUrl+'/v2/transactions/branding-info', httpOptions);
  }

  getStatementByDate(userSessionToken: number, startDate: string, endDate: string): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<any>(this.itemUrl+'/v2/statements?from='+startDate+'&to='+endDate, httpOptions);
  }

  sendStatementByDate(userSessionToken: number, startDate: string, endDate: string): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.post<any>(this.itemUrl+'/v2/statements?from='+startDate+'&to='+endDate, {}, httpOptions);
  }

  // create Budget Entry
  createBudgetEntry(userSessionToken: number, period: string, type: string, amount: number): Observable<any> {
    const post: { period: string, type: string, amount: number} = {
      period: period,
      type: type,
      amount: amount
    };
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/budgetentries/create/';
    return this.http.put<any>(apiURL, post, httpOptions);
  }

  // get Budget entries
  getBudgetEntries(userSessionToken: number): Observable<any[]> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    return this.http.get<any>(this.itemUrl+'/v2/budgetentries/', httpOptions);
  }

  // remove Reality Check
  removeBudgetEntry(userSessionToken: number, id: number): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) }
    const apiURL = this.itemUrl+'/budgetentries/destroy/'+id;
    return this.http.get<any>(apiURL, httpOptions);
  }

  // switch to pending budget entry
  switchToPending(userSessionToken: number, id: number): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) }
    const apiURL = this.itemUrl+'/budgetentries/switchtopending/'+id;
    return this.http.get<any>(apiURL, httpOptions);
  }

  // reject pending data and revert to original
  rejectPending(userSessionToken: number, id: number): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) }
    const apiURL = this.itemUrl+'/budgetentries/rejectpending/'+id;
    return this.http.get<any>(apiURL, httpOptions);
  }


  // remove Reality Check
  markBudgetEntryForDeletion(userSessionToken: number, id: number): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) }
    const apiURL = this.itemUrl+'/budgetentries/markfordeletion/'+id;
    return this.http.get<any>(apiURL, httpOptions);
  }

  updateUserSettingById(userSessionToken: string, id, value): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) }
    const post = {value: value};
    return this.http.patch<any>(this.itemUrl+'/v2/users/me/settings/'+id, post, httpOptions);
  }

  /**
   * get User data
   * @param userSessionToken
   */
  getMe(userSessionToken: string): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) }
    return this.http.get<any>(this.itemUrl+'/v2/users/me', httpOptions);
  }

  /**
   * Get Banking Info
   * @param postBody
   */
  getPartnerInfo(userSessionToken) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) }
    return this.http.get<any>(this.itemUrl+'/v2/partners/main-info', httpOptions);
  }

  /**
   * get home page data
   * @param userSessionToken
   */
  getHome(userSessionToken, gameType) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v3/home/'+gameType;
    return this.http.get<any>(apiURL, httpOptions);
  }

  // remove Reality Check
  changeBudgetEntry(userSessionToken: number, id: number, type: string): Observable<any> {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) }
    const apiURL = this.itemUrl+'/v2/budgetentries/change/'+id;
    const post = {
      type: type,
    };
    return this.http.post<any>(apiURL, post, httpOptions);
  }

  /**
   * Personal Timeout or Self Exclusion
   * @param userSessionToken
   */
  timeoutOrExclusion(userSessionToken, type, period) {
    httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer '+userSessionToken}) };
    const apiURL = this.itemUrl+'/v3/user/block';
    return this.http.post<any>(apiURL, {
      type: type,
      period: period
    }, httpOptions);
  }

}
