import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment as ENV} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Platform, ToastController} from '@ionic/angular';
import {SpriteSync} from '../../models/SpriteSync';



declare const navigator: any;
declare const cordova: any;
const win: any = window;

@Injectable({
  providedIn: 'root'
})
export class SpritessyncService {
//todo will move to config after backend url will privided from Gary
  // spritesUrl = 'http://shpola1school.org.ua/zfort/json-call.php';
  serverURl: string = ENV.serverURl;
  itemUrl: string = this.serverURl+'api/assets';
  loading;
  spritesPath = '';
  localSprites;
  private soundsSpritesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private spritesSounds;
  private newSpriteSounds;



  constructor(
    public http: HttpClient,
    private plt: Platform,
    public toastCtrl: ToastController
  ) {
    // console.log('SpritessyncProvider Provider loaded');
    this.plt.ready().then(() => {
        this.getSprites();
        // this.initSprites();
    });
  }

  initSprites() {
    this.localSprites = JSON.parse(localStorage.getItem('spritesSounds'));
  }



  async getSprites() {
    //internet connection check
    // if(navigator.connection.type == 'none' || navigator.connection.type == 'unknown') {
    if(navigator.connection.type === 'none' || navigator.connection.type === 'unknown') {
      await this.presentToast('There was a problem with internet connection.', 10000);
      return false;
    }

    this.spritesSounds = JSON.parse(localStorage.getItem('spritesSounds')) || null;
    console.log('spritesSounds1', this.spritesSounds);
    // const dirExist = await this.checkDirectory();
    // if(!dirExist) {
    //   await this.createDirectory();
    // }
    // this.newSpriteSounds = {
    //   sprites: [],
    //   gameTemplateEvent: [],
    //   general: []
    // };
    // if(this.spritesSounds) {
    //   this.newSpriteSounds = this.spritesSounds;
    // }
    this.getSpriteJson().subscribe(async (res: any) => {
      if(res.apiCode === 'SUCCESS' && res.apiStatus === 'OK') {
        // console.log('getSpriteJson', res);
        const data = res.data;
        console.log('data', data);
        localStorage.setItem('spritesSounds', JSON.stringify(res.data));
        console.log('Download finished');
        this.soundsSpritesLoaded.next(true) ;
      } else {
        await this.presentToast('There was a problem', 3000);
      }

    });
  }

  /**
   * get Sptires and sounds json
   */
  getSpriteJson(): Observable<SpriteSync[]> {
    return this.http.get<SpriteSync[]>(this.itemUrl);
  }

  /**
   * get soundId or array of sounds ids
   * @param sound can be string or array
   *
   * return string url
   */
  getSounds(type, sound: any, attr = 'label') {
    // const path = this.file.dataDirectory + 'spritesSounds/' + type + '/file_';
    const sKey = 'sounds';
    const SFX = JSON.parse(localStorage.getItem('spritesSounds'))[sKey][type];
    try {
      const snd = SFX.find(x => x[attr] === sound);
      // console.log('snd', snd);
      // let file = path + snd.id + '.' + snd.url;
      return snd.url;
    } catch(e) {
      // console.log('get sound error', e);
    }
  }


  getSpriteImageByIndex(gameObj, event, spriteIndex) {
    if(!this.localSprites) {
      this.initSprites();
    }
    const sprId = gameObj.game_template.base_sprite_sheet_id;
    // let image =  this.spritesPath+'file_' + sprId;
    const locSpr = this.localSprites.sprites.find(x => x.id === sprId);
    // image = image+'.' + this.getExtensionFromString(locSpr.url);
    const row = Math.floor(spriteIndex/10);
    const yPos = (row * locSpr.height);
    const xPos = (((spriteIndex - (row * 10)) * locSpr.width));

    // eslint-disable-next-line max-len
    const pos = 'background-image: url(' + locSpr.url + ');background-position: -' + xPos + 'px -' + yPos + 'px';
    return pos;
  }

  getExtensionFromString(str) {
    const ext = str.split('/').pop();
    return  ext.split('.').pop();
  }

  async presentToast(msg, dur, position = 'top') {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: dur,
      position: 'top'
    });
    toast.present();
  }
}
