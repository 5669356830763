import { Component, OnInit, Input } from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-game-over-dialog',
  templateUrl: './game-over-dialog.component.html',
  styleUrls: ['./game-over-dialog.component.scss'],
})
export class GameOverDialogComponent implements OnInit {
  @Input() pot: any;
  @Input() winners: any;
  @Input() winnersByLine: any;
  @Input() meta: any;
  @Input() game: any;
  @Input() showPopup: boolean;
  @Input() userId: number;
  @Input() winnersTotal: any;
  text: string;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  gotoGames(){
    // this.navCtrl.parent.select(0);
    this.router.navigate(['/tabs/tabs/games']);
  }

  showRightIcon(score1, score2) {

    if((score1 > 0 && score1 < 3) || (score2 > 0 && score2 < 3)){
      return 'game_results_icon2';
    }else if(score1 > 3 || score2 > 3) {
      return 'game_results_icon3';
    }
    return 'game_results_icon';
  }

  cropTeamName(name) {
    if(name){
      const words = name.split(' ');
      let newName = '';
      words.forEach(value => {
        // console.log('value', value);
        // console.log('value[0]', value[0]);
        newName = newName+value[0];
      });
      // console.log('newName', newName);
      return newName;
    }
    return name;
  }

  close() {
    this.showPopup = false;
  }

}
