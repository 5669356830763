import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';
@Pipe({
  name: 'sortArr'
})
export class SortArrPipe implements PipeTransform {

  moment = moment;
  transform(values: any, attribute: string, typeSorting?: string ): any {
    // console.log('values', values , attribute ,typeSorting);
    if(typeSorting==='ASC'){
      values = values.sort((val1, val2)=> {return new Date(val1[attribute]).getTime() - new Date(val2[attribute]).getTime()});
    } else {
      values = values.sort((val1, val2)=> {return new Date(val2[attribute]).getTime() - new Date(val1[attribute]).getTime()});
    }
    return values;
  }

}
