import { Component, OnInit, Input } from '@angular/core';
import {SpritessyncService} from '../../services/spritessync/spritessync.service';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnInit {
  @Input() game: any;
  @Input() cardEvent: any;
  @Input() team: number;
  @Input() hit: number;
  @Input() cantWins: number;
  // @Input() spritesheet: any;
  @Input() itemW: number;
  text: string;
  wins = false;
  spritesPath = '';
  localSprites;
  eventIconStyle = {};
  flipIcons = false;

  constructor(
    public sprSync: SpritessyncService
  ) {
    // console.log('EventItemComponent');
    // this.spritesPath = this.file.dataDirectory + 'spritesSounds/sprites/';
    this.localSprites = JSON.parse(localStorage.getItem('spritesSounds'));
    this.animateTimeout();
  }

  ngOnInit() {}


  setSpritePosition(cardEvent) {
    // console.log('cardEvent', cardEvent);
    // console.log('this.spritesPath', this.spritesPath);
    // console.log('cardEvent', cardEvent);
    // console.log('this.game', this.game.game_template);


    let sprId = this.game.game_template.base_sprite_sheet_id;
    let image:any =  '';

    //detect hit sprite
    if(cardEvent.happened && !cardEvent.is_win){
      sprId = this.game.game_template.hit_sprite_sheet_id;
      image = this.spritesPath+'file_' + sprId;
    }

    // console.log('image', image);


    //detect win sprite
    if(cardEvent.is_win) {
      sprId = this.game.game_template.win_sprite_sheet_id;
      image = this.spritesPath+'file_' + sprId;
    }

    const locSpr = this.getLocalSpriteData(sprId);
    // console.log('locSpr', locSpr);
    // image = image+'.'+this.sprSync.getExtensionFromString(locSpr.url);


    const row = Math.floor(cardEvent.sprite_index/10);
    const yPos = (row * locSpr.height);
    const xPos = (((cardEvent.sprite_index - (row * 10)) * locSpr.width));

    const pos = {
      'background-image': 'url(' + locSpr.url + ')',
      'background-position': '-' + xPos + 'px -' + yPos + 'px'
    };
    // console.log('index, row, yPos, xPos', index, row, yPos, xPos)
    return pos;
  }

  getExtensionFromString(str) {
    const ext = str.split('/').pop();
    return  ext.split('.').pop();
  }

  getLocalSpriteData(id) {
    return this.localSprites.sprites.find(x => x.id === id);
  }

  showPopover(event){

  }

  animateTimeout() {
    setTimeout(() => {
      this.flipIcons = true;
    },3000);
  }
}
