import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {Events} from '../../services/events/events.service';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-announce',
  templateUrl: './announce.component.html',
  styleUrls: ['./announce.component.scss'],
})
export class AnnounceComponent implements OnInit {
  @Input() pageName: string;
  @Output() onClose = new EventEmitter();
  closeAvailable: boolean = false;
  buttonAvailable: boolean = false;
  buttonString: string = '';
  buttonTab: number = 0;
  announceTitle: string = '';
  showDialog: boolean = false;
  announceContent: string = '';
  buttonPage:  string = '';
  gameId: number;
  gamesLive: number;
  constructor(
    public events: Events,
    private router: Router
  ) {
    events.subscribe('announce:changed', (announce) => {
      // console.log('announce', announce);
      // console.log('this.router.url', this.router.url);
      // console.log('bnmbnb this announce:changed: ', this.pageName);
      // console.log('bnmbnb Active announce:changed: ', this.app.getActiveNav().getActive().name);
      // console.log('bnmbnb this announce:changed object: ', JSON.stringify(announce));
      if (this.router.url.split('?')[0] === this.pageName) {  // only announce on active page
        this.buttonAvailable = announce.button;
        this.closeAvailable = announce.close;
        if (this.buttonAvailable) {
          this.buttonString = announce.buttonString;
        } else {
          this.buttonString = '';
        }
        this.announceTitle = announce.title;
        this.announceContent = announce.content;
        this.buttonPage = announce.buttonPage;
        this.buttonTab = announce.buttonTab;
        this.gameId = announce.gameId;
        this.gamesLive = announce.gamesLive;
        // console.log("dfad1 game: "+this.gameId);
        this.showDialog = true;
      }
    });
  }

  ngOnInit() {}

  closeDialog(){
    this.resetAnnounce();
  }

  gotoPage(){
    this.resetAnnounce();
    // h1 if page is  LiveCardPage(i.e. the game) pass game ID


    if (this.buttonPage !== '/tabs/tabs/mycards/livecard' &&  this.buttonPage !== '/tabs/tabs/mycards/livecardf1') {
      // const tabs = this.navCtrl.parent;
      // tabs.select(this.buttonTab).then(() => tabs.getSelected().setRoot(this.buttonPage)).then(() => this.navCtrl.popToRoot());
      this.router.navigate([this.buttonPage]);
    } else {
      // const tabs = this.navCtrl.parent;
      // console.log("dfad2 game: "+this.gameId);
      const navigationExtras: NavigationExtras = {
        queryParams: {
          gameId: JSON.stringify({gameId: this.gameId})
        }
      };
      if(this.gamesLive > 0  && this.gamesLive < 2) {
        this.router.navigate([this.buttonPage], navigationExtras);
        // tabs.select(this.buttonTab).then(() => tabs.getSelected().setRoot(this.buttonPage, {
        //   gameId: this.gameId
        // })).then(() => this.navCtrl.popToRoot());
      }else {
        this.router.navigate(['/tabs/tabs/mycards'], navigationExtras);
        // tabs.select(this.buttonTab).then(() => tabs.getSelected().setRoot('HeldCardsPage', {
        //   gameId: this.gameId
        // })).then(() => this.navCtrl.popToRoot());
      }

    }
  }

  resetAnnounce(){
    this.showDialog = false;
    this.onClose.emit('dialog closed');
  }

}
