import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class ExclusionService {

  constructor(
    public api: ApiService,
    private storage: Storage,
  ) { }


  /**
   * Check if user blocked or no
   */
  checkExclusion(userSessionToken, area){
    return new Promise(async (resolve, reject) => {
      if(userSessionToken === 1){
        userSessionToken = await this.getUserToken();
      }

      this.api.checkUserExclusion(userSessionToken, area).subscribe(exclusion => {
        if(exclusion.apiCode === 'SUCCESS' && exclusion.apiStatus === 'OK'){
          resolve({result: true});
        }else if(exclusion.apiCode === 'EXCLUDED') {
          resolve({result: false, data: exclusion});
        }else {
          resolve({result: false, data: exclusion});
        }
      }, err => reject(false));
    });
  }


  getUserToken() {
    return new Promise(async resolve => {
      this.storage.get('userSessionToken').then((userSessionToken) => {
        resolve(userSessionToken);
      });
    });
  }
}
