import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortArray'
})
export class SortArrayPipe implements PipeTransform {

  /**
   * Takes a array and sort ASC ad DESC.
   */
  transform(values: any, attribute: string, typeSorting?: string, ): any {
    // console.log('typeSorting', values, typeSorting, attribute);
    if(typeSorting==='ASC'){
      // console.log('typeSorting===ASC');
      // console.log('values', values);

      values = values.sort((val1, val2)=> {return <any>new Date(val1.game[attribute]) - <any>new Date(val2.game[attribute])});
      // values = values.sort((a, b) => a['created_at'] > b.created_at ? 1 : a.created_at);
      // console.log('values after', values);
    } else {
      values = values.sort((val1, val2)=> {
        return <any>new Date(val2.game[attribute]) - <any>new Date(val1.game[attribute])
      });
      // values = values.sort((a, b) => a['created_at'] > b.created_at ? -1 : a.created_at);
    }
    // console.log('values', values)
    return values;
  }

}
