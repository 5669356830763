import {Component, NgZone, OnInit} from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {NavigationExtras, Router} from '@angular/router';
import {ApiService, ExclusionService, Events, PusherService, SpritessyncService } from './services/services';
import { environment as ENV } from '../environments/environment' ;
import {ToastController, Platform} from '@ionic/angular';
import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';
import {TabsService} from './services/tabs/tabs.service';
import {PushserviceService} from './services/services';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  showInfoComponent = false;
  showInfoWindow = false;
  inforWindowTitle = 'User blocked';
  inforWindowDescription = '';
  inforWindowCloseText = 'OK'
  constructor(
    private storage: Storage,
    private router: Router,
    public excl: ExclusionService,
    public api: ApiService,
    public pusherServ: PusherService,
    public events: Events,
    public toastController: ToastController,
    public platform: Platform,
    private ngZone: NgZone,
    public sprSync: SpritessyncService,
    public tabs: TabsService,
    public pushServ: PushserviceService,
  ) {



    this.events.subscribe('http:forbidden', error => {
      this.router.navigate(['welcome']);
    });

    platform.ready().then(async () => {
      if (localStorage.getItem('gameSounds') == null) {
        localStorage.setItem('gameSounds', 'true');
      }


      (window as any).handleOpenURL = (url: string) => {
        // this context is called outside of angular zone!
        setTimeout(() => {
          // so we need to get back into the zone..
          this.ngZone.run(() => {
            // this is in the zone again..
            this.handleOpenUrl(url);
          });
        }, 0);
      };

      // check if app was opened by custom url scheme
      const lastUrl: string = (window as any).handleOpenURL_LastURL || '';
      if (lastUrl && lastUrl !== '') {
        delete (window as any).handleOpenURL_LastURL;
        this.handleOpenUrl(lastUrl);
      }

      // this.firebaseStats.setCollectionsEnabled();


      //check if country is GB
      // this.cCheck.checkCountry().subscribe(contryCheckRes => {
      //   // console.log('checkCountry result', contryCheckRes);
      //   this.cCheck.checkAppVersion().subscribe(appcheck => {
      //   })
      // }, error => {
      //   console.log('checkCountry error', error);
      // });

      // this.network.onDisconnect().subscribe(() => {
      //   console.log('network was disconnected :-(');
      //   this.storage.set('internetConnection', false);
      // });


      // this.network.onConnect().subscribe(() => {
      //   console.log('network connected!');
      //   this.storage.set('internetConnection', true)
      // });

    });
  }

  private handleOpenUrl(url: string) {
    // custom url parsing, etc...

    // navigate to page with reactive forms
    console.log('Magic link', url)
  }

   async ngOnInit() {
     // If using a custom driver:
     // await this.storage.defineDriver(MyCustomDriver)
     await this.storage.create().then();
     this.storage.get('userSessionToken').then(userSessionToken => {
       console.log('userSessionToken', userSessionToken);
       if (userSessionToken == null || userSessionToken === '') {
         // this.pushServ.setupPush({email: '', partnerId: 3});
         this.router.navigate(['tutorial']);
       } else {

         this.checkLoginExlusion(userSessionToken);
       }
     });
   }

  async checkLoginExlusion (userSessionToken) {
    if(!userSessionToken) return false;
    this.excl.checkExclusion(userSessionToken, 'login').then((notExcluded: any) => {
      // notExcluded.result = false;
      // notExcluded.data = {
      //   apiCode: 'sdfsd',
      //   length: 12,
      //   since: {date: '123-34233 '},
      //   reason: 'self'
      // };
      // console.log('notExcluded', notExcluded);
      if(notExcluded.result){
        this.api.userDetails(userSessionToken).subscribe(userDetail => {
          const user = userDetail.data.user;
          // this.firebaseStats.setUserId(user.id);
        });

        this.router.navigate(['/tabs/tabs/home']);
        // this.app.getRootNav().setRoot('LiveCardF1Page', {gameId: 123});
        //
        // this.rootPage = Tab5Root;
      } else if (notExcluded.data.apiCode === 'EXCLUDED') {
        this.inforWindowDescription = 'Your account has been restricted for ' + notExcluded.data.length + ' from ' + notExcluded.data.since + '. Please contact ' + ENV.supportEmail + ' for more information.';
        if (notExcluded.data.reason === 'self') {
          this.inforWindowDescription = this.inforWindowDescription + '<br /> To self-exclude from participating online gambling companies licensed in Great Britain go to <a href="https://www.gamstop.co.uk">https://www.gamstop.co.uk.</a>';
        }
        this.events.publish('showInfoDialog');
      } else if(notExcluded.data.apiCode === 'GAMSTOP') {
        this.inforWindowDescription = 'Your account has been restricted. Please contact <a href="mailto:'+ENV.supportEmail+'">' + ENV.supportEmail + '</a> for more information.'
        this.events.publish('showInfoDialog');
      } else {
        this.presentToast('There was a problem logging you in. Please try again or click the link below to get a password reminder', 10000);
      }
    }).catch((error) => {
      this.storage.remove('userSessionToken');
      this.router.navigate(['welcome']);
      this.pusherServ.deInitChannels();
    });
  }

  needShow() {
    console.log('showInfoComponent');
    this.showInfoComponent = true;
  }

  clearUserSession() {
    this.storage.remove('userSessionToken');
    this.storage.remove('userCreatedAt');
    this.goTo('welcome');
  }

  goTo(page: string) {
    this.router.navigate([page]);
  }

  async presentToast(msg, dur, position = 'top') {
    const toast = await this.toastController.create({
      message: msg,
      duration: dur,
      position: 'top'
    });
    toast.present();
  }




}
