import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-live-card-ads',
  templateUrl: './live-card-ads.component.html',
  styleUrls: ['./live-card-ads.component.scss'],
})
export class LiveCardAdsComponent implements OnInit {
  @Input() sponsorship: any;
  constructor() { }

  ngOnInit() {}

  tapTosponsorship() {

  }

}
