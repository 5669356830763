import { Injectable } from '@angular/core';
import {OneSignal} from 'onesignal-ngx';
import {environment as ENV} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PushserviceService {

  constructor(private oneSignl: OneSignal) { }

  async setupPush(account) {
    const res1 = await this.oneSignl.init({
      appId: ENV.onesignal.appId
    });
    console.log('res1', res1);
    const res2 = await this.oneSignl.registerForPushNotifications();
    console.log('res2', res2);

    console.log('$$$$$$$$$$$$ setupPush');

    this.oneSignl.addListenerForNotificationOpened((jsonData) => {
      console.log('setNotificationOpenedHandler: ' + JSON.stringify(jsonData));
    });

    const tag = account.email + account.partnerId;
    this.oneSignl.sendTag('emailId', tag);
  }


  cancelNotification() {
    // this.oneSignal.cancelNotification().subscribe);
  }
}
