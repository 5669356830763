import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prepareWinners'
})
export class PrepareWinnersPipe implements PipeTransform {

  transform(value: any, ...args) {
    value.forEach((line) => {
      const endWinnersList = [];
      (line.winners).forEach(winner => {
        const existWinnerIndex = endWinnersList.findIndex(u => u.id == winner.id)
        if(existWinnerIndex == -1){
          winner.xLines = 1;
          endWinnersList.push(winner);
        }else {
          if(endWinnersList[existWinnerIndex].hasOwnProperty('xLines')){
            console.log('endWinnersList[existWinnerIndex].xLines', endWinnersList[existWinnerIndex].xLines);
            endWinnersList[existWinnerIndex].xLines = endWinnersList[existWinnerIndex].xLines+1;
          }else {
            endWinnersList[existWinnerIndex].xLines = 1;
          }
        }
      });
      line.winners = endWinnersList;
      (line.winners).forEach(winner => {
        if(winner.hasOwnProperty('xLines')){
          if(winner.xLines > 1) {
            winner.username = winner.username + ' (x'+winner.xLines+')';
          }
        }
      });
    });
    console.log('value', value);
    return value;
  }

}
