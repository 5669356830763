import { Injectable } from '@angular/core';
import {Platform, ToastController} from '@ionic/angular';
import {environment as ENV} from '../../../environments/environment';
import {ApiService } from '../services';
import {Observable} from 'rxjs';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import {Storage} from '@ionic/storage-angular';


@Injectable({
  providedIn: 'root'
})
export class CountrycheckService {
  devMode: boolean = ENV.countryCheckDevMode;
  acceptLocations = [
    'GB',   // also Orkney
    'IM',   // Isle of man
    'GG'    // Guernsey
  ];

  settings = {
    countryCheck: false,
    appVersion: false
  };
  needShowToast = true;
  internetToast = null;
  constructor(
    public platform: Platform,
    private geolocation: Geolocation,
    public api: ApiService,
    public toastCtrl: ToastController,
    private storage: Storage
  ) { }


  checkCountry() {
    // @ts-ignore
    return new Observable(async (observer) => {

      // this.settings.countryCheck = this.storage.get('settings.countryCheck').then((val) => {
      //   if (val !== null) {
      //     return val;
      //   }
      //   return false;
      // });
      // console.log('checkCountry devMode status:', this.devMode)
      if (!ENV.isProdConfig && !this.settings.countryCheck) {
        observer.next('UA');
        return;
      }

      this.getDeviceLocation().subscribe((res: any) => {
        // console.log('this.getDeviceLocation', res);
        console.log('check location By Coordinates');
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.getCurrentCountry(res.latitude, res.longitude).subscribe((country_code: string) => {
          // console.log('country_code', country_code);
          if (!this.acceptLocations.includes(country_code)) {
            this.showToastMessage();
            observer.error(false);
          } else {
            observer.next(country_code);
          }
        }, error => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          this.getCountry().subscribe((country_code: string) => {
            if (!this.acceptLocations.includes(country_code)) {
              this.showToastMessage();
              observer.error(false);
            } else {
              observer.next(country_code);
            }
          });
        });

      }, error => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.getCountry().subscribe((country_code: string) => {
          if (!this.acceptLocations.includes(country_code)) {
            this.showToastMessage();
            observer.error(false);
          } else {
            observer.next(country_code);
          }
        });
      });
    });
  }

  getCountry() {
    return new Observable((observer) => {
      console.log('check location By IP');
      this.api.countryCheck().subscribe(country => {
        // console.log('countryCheck result:', country.location);
        // console.log('this.acceptLocations', this.acceptLocations.includes(country.location));
        if(!(country.hasOwnProperty('apiStatus') && country.apiStatus === 'OK' && this.acceptLocations.includes(country.location) )) {
          this.showToastMessage();
          observer.error(false);
        }else {
          observer.next(country.location);
        }
      }, error => {
        // console.log('countryCheck error', error);
        this.showToastMessage();
        observer.error(false);
      });
    });
  }


  getDeviceLocation() {
    return new Observable((observer) => {
      this.platform.ready().then(() => {
        this.geolocation.getCurrentPosition({ timeout: 1000 }).then((res) => {
          console.log('coordinates resp', res);
          observer.next(res.coords);
        }, (e) => {
          console.log('Error getting location', e);
          observer.error(false);
        });
      });
    });
  }

  getCurrentCountry(lat,lng){
    return new Observable((observer) => {
      const geocodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+ENV.gMapsApiKey;
      this.api.getGeocode(geocodeUrl).subscribe(geoData => {
        if (geoData.status === 'OK') {
          console.log('getCurrentCountry', geoData);
          const addressComponents = geoData.results[0].address_components;
          const address = addressComponents.filter(r=>{
            if(r.types[0] === 'country'){
              return r;
            }
          }).map(r => r.short_name);
          observer.next(address[0]);
        }else {
          observer.error(false);
        }
      });
    });
  }


  async showToastMessage() {
    const toast = await this.toastCtrl.create({
      message: 'You are not allowed to play this game outside \n of the UK',
      duration: 4000,
      position: 'top'
    });
    await toast.present();
  }


  // checkAppVersion() {
  //
  //
  //
  //   let wrongVersion = false;
  //   // @ts-ignore
  //   return new Observable(async (observer) => {
  //
  //
  //     this.settings.appVersion = await this.storage.get('settings.appVersion').then((val) => {
  //       if (val !== null) {
  //         return val;
  //       }
  //       return false;
  //     });
  //     if (!ENV.isProdConfig && !this.settings.appVersion) {
  //       observer.next(true);
  //       return;
  //     }
  //
  //
  //     if (this.platform.is('cordova')) {
  //       this.appVersion.getVersionNumber().then(appVersion => {
  //         // appVersion = '1.0.3';
  //         this.items.checkAppVersion().subscribe(serverVersion => {
  //           if ((serverVersion.hasOwnProperty('apiStatus') && serverVersion.apiStatus === 'OK')) {
  //             // serverVersion.message = '1.0.2';
  //             const appVersionSplit = appVersion.split('.');
  //             const serverVersionSplit = serverVersion.message.split('.');
  //             // console.log('appVersion', appVersionSplit);
  //             // console.log('serverVersion', serverVersionSplit);
  // eslint-disable-next-line max-len
  //             // console.log('appVersionSplit[0] < serverVersionSplit[0]', parseInt(appVersionSplit[0]) < parseInt(serverVersionSplit[0]))
  // eslint-disable-next-line max-len
  //             // console.log('appVersionSplit[1] < serverVersionSplit[1]', parseInt(appVersionSplit[1]) < parseInt(serverVersionSplit[1]))
  // eslint-disable-next-line max-len
  //             // console.log('appVersionSplit[2] < serverVersionSplit[2]', parseInt(appVersionSplit[2]) < parseInt(serverVersionSplit[2]))
  //             if (parseInt(appVersionSplit[0]) < parseInt(serverVersionSplit[0])) {
  //               wrongVersion = true;
  //             }
  //             if (parseInt(appVersionSplit[1]) < parseInt(serverVersionSplit[1])) {
  //               wrongVersion = true;
  //             }
  //             if (parseInt(appVersionSplit[2]) < parseInt(serverVersionSplit[2])) {
  //               wrongVersion = true;
  //             }
  //             console.log('wrongVersion', wrongVersion);
  //
  //             if (wrongVersion) {
  //               let toast = this.toastCtrl.create({
  //                 message: 'Your version is outdated. \n Please update the app to latest version.',
  //                 duration: 5000,
  //                 position: 'top'
  //               });
  //               toast.present();
  //               toast.onDidDismiss(() => {
  //                 console.log('Dismissed toast');
  //                 this.platform.exitApp();
  //               });
  //             }
  //
  //             observer.next(true);
  //           }
  //         }, error => {
  //           observer.error(false);
  //         });
  //         // observer.next(res);
  //       }).catch(err => {
  //         observer.error(false);
  //       });
  //     } else {
  //       observer.next(true);
  //     }
  //
  //   });
  // }
  //
  //
  checkInternet() {
    return new Promise(async resolve => {
      // console.log('navigator.connection.type', navigator.connection.type);
      if (this.platform.is('cordova')) {
        if (navigator.connection.type === 'none' || navigator.connection.type === 'unknown') {
          const toast = await this.toastCtrl.create({
            message: 'There was a problem with internet connection.',
            duration: 10000,
            position: 'top'
          });
          await toast.present();
          resolve({'result': false});
        } else {
          resolve({'result': true});
        }
      } else {
        resolve({'result': true});
      }
    });
  }

  async showHideInternetConnectionToast() {
    this.internetToast = await this.toastCtrl.create({
      message: 'There was a problem with internet connection.',
      duration: 3000,
      position: 'top'
    });

    if (navigator.connection.type === 'none' || navigator.connection.type === 'unknown') {
      if (this.needShowToast) {
        this.needShowToast = false;
        this.internetToast.present();
      }

    }

    this.internetToast.onDidDismiss(() => {
      console.log('Dismissed toast');
      this.needShowToast = true;
    });
  }
}

