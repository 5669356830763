import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-my-card-item',
  templateUrl: './my-card-item.component.html',
  styleUrls: ['./my-card-item.component.scss'],
})
export class MyCardItemComponent implements OnInit {
  moment = moment;
  text: string;
  @Input() card: any;
  @Input() cardType: string;
  @Input() gameType: string;
  @Input() runAt: string;
  @Input() team0: any;
  @Input() team2: any;
  @Input() gameName: string;
  @Input() gameDescription: string;
  @Input() gameCountry: string;
  @Input() gameFlag: string;
  @Input() gameMap: string;
  @Input() showTime: boolean = true;
  constructor() { }

  ngOnInit() {}


  isToday(date) {
    const compareDate = moment.utc(date).local();
    return compareDate.isSame(moment(), 'day');
  }

  convertTime(dateTime) {
    return moment.utc(dateTime)
  }
}
