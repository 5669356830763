import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-live-mini-cards',
  templateUrl: './live-mini-cards.component.html',
  styleUrls: ['./live-mini-cards.component.scss'],
})
export class LiveMiniCardsComponent implements OnInit {
  @Input() heldCards: any;
  @Input() slides: any;
  @Input() card: any;
  @Input() activeCardId: any;
  @Input() cardEvents: any;
  @Input() cardsEvents: any;
  @Input() miniCardArray: any;
  constructor() { }

  ngOnInit() {}


  openCard(card: any, index, isFirstRun = false) {
    // console.log('card: any, index, isFirstRun = false', card, index, isFirstRun);/**/
    this.card = card;
    this.activeCardId = card.id;
    // this.wins = this.wins1[card.id];
    // this.cantWins = this.cantWins1[card.id];
    this.cardEvents = this.cardsEvents[card.id];
    // console.log('this.cardEvents', this.cardEvents);
    // this.hits = this.hits1[card.id];
    if(!isFirstRun) {
      this.slides.slideTo(index, 500);
    }else {
      this.slides.lockSwipes(this.heldCards.length > 1 ? false : true);
    }
  }

  getMinicardEvents(events, id) {
    let evnts =[];
    events[id].map((value) => evnts.push(value));
    evnts = evnts.sort((val1, val2)=> {return val1['pos_index'] - val2['pos_index']});
    return evnts;
  }

  miniCardWidth(){
    const visScale = [0, 1,1.5,1.3,1.1,1];
    const minicardwidth = {
      // 'width': this.itemWpx/5*visScale[this.cardCount] + "px",
      width: '9.5px',
      height: '9.5px'
    };
    return minicardwidth;
  }

  setMyClasses(cardEvent) {
    // console.log('heldCard', heldCard);
    // let win = this.wins1[cardId][index];
    // let win = false;
    // let cantwin = this.cantWins1[cardId][index];
    // let hit = this.hits1[cardId][index];
    const classes = {
      // "mini-card-cantwin": cantwin,
      'mini-card-win': cardEvent.is_win,
      'pulsate-fwd': cardEvent.is_win,
      'mini-card-hit': cardEvent.happened && !cardEvent.is_win,
      //"mini-card-neutral": !win && !cantwin && !hit,
      'mini-card': 1
    };
    return classes;
  }

  detectWinCard(events, id, activeCardId) {
    let evnts =[];

    events[id].map((value) => evnts.push(value));
    evnts = evnts.sort((val1, val2)=> {return val1['pos_index'] - val2['pos_index']})
    const isWinExist = (id === activeCardId && evnts.find(e => e.is_win));
    // console.log('isWinExist', isWinExist);
    // is_win: true
    return isWinExist;
  }

  detectAnyTimeFullHouse(events, id, activeCardId) {
    let evnts =[];

    events[id].map((value) => evnts.push(value));
    evnts = evnts.sort((val1, val2)=> {return val1['pos_index'] - val2['pos_index']})
    // evnts[0]['isAnytimeFullHouse'] = true
    // console.log('evnts', evnts);
    const isWinExist = (id === activeCardId && evnts.find(e => e.any_time_full_house_win));
    // console.log('isWinExist', isWinExist);
    // is_win: true
    return isWinExist;
  }

}
