import { NgModule } from '@angular/core';
import { SortArrayPipe } from './sort-array/sort-array.pipe';
import { ByPassSecurityPipe } from './by-pass-security/by-pass-security.pipe';
import { AbbreviationPipe } from './abbreviation/abbreviation.pipe';
import { ReformatDatesPipe } from './reformat-date/reformat-dates.pipe';
import { SortArrPipe } from './sort-arr/sort-arr.pipe';
import { PrepareWinnersPipe } from './prepare-winners/prepare-winners.pipe';
@NgModule({
	declarations: [
    SortArrayPipe,
    ByPassSecurityPipe,
    AbbreviationPipe,
    ReformatDatesPipe,
    SortArrPipe,
    PrepareWinnersPipe
  ],
	imports: [],
  exports: [
    SortArrayPipe,
    ByPassSecurityPipe,
    AbbreviationPipe,
    ReformatDatesPipe,
    SortArrPipe,
    PrepareWinnersPipe
  ]
})
export class PipesModule {}
