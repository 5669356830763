import { NgModule } from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {PipesModule} from '../pipes/pipes.module';
import {InfoWindowDialogComponent } from './info-window-dialog/info-window-dialog.component';
import {BalanceComponent } from './balance/balance.component';
import {MyCardItemComponent } from './my-card-item/my-card-item.component';
import {RealityCheckComponent} from './reality-check/reality-check.component';
import {AnnounceComponent} from '../components/announce/announce.component';
import {MyCardsComponent} from './my-cards/my-cards.component';
import {GameOverDialogComponent} from './game-over-dialog/game-over-dialog.component';
import {WinnerDialogComponent} from './winner-dialog/winner-dialog.component';
import {HowToWinDialogComponent} from './how-to-win-dialog/how-to-win-dialog.component';
import {ActiveLinesComponent} from './active-lines/active-lines.component';
import {LiveCardAdsComponent} from './live-card-ads/live-card-ads.component';
import {LiveMiniCardsComponent} from './live-mini-cards/live-mini-cards.component';
import {EventItemComponent} from './event-item/event-item.component';
import{YouWonDialogComponent} from './you-won-dialog/you-won-dialog.component';
import {BingoalClaimedComponent} from './bingoal-claimed/bingoal-claimed.component';
import {GamblingLinksComponent} from './gambling-links/gambling-links.component';
import {DirectivesModule} from '../directives/directives.module';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    InfoWindowDialogComponent,
    BalanceComponent,
    MyCardItemComponent,
    RealityCheckComponent,
    AnnounceComponent,
    MyCardsComponent,
    GameOverDialogComponent,
    WinnerDialogComponent,
    HowToWinDialogComponent,
    ActiveLinesComponent,
    LiveCardAdsComponent,
    LiveMiniCardsComponent,
    YouWonDialogComponent,
    EventItemComponent,
    BingoalClaimedComponent,
    GamblingLinksComponent
  ],
    imports: [IonicModule, CommonModule, PipesModule, DirectivesModule, FormsModule],
  exports: [
    InfoWindowDialogComponent,
    BalanceComponent,
    MyCardItemComponent,
    RealityCheckComponent,
    AnnounceComponent,
    MyCardsComponent,
    GameOverDialogComponent,
    WinnerDialogComponent,
    HowToWinDialogComponent,
    ActiveLinesComponent,
    LiveCardAdsComponent,
    LiveMiniCardsComponent,
    YouWonDialogComponent,
    EventItemComponent,
    BingoalClaimedComponent,
    GamblingLinksComponent
  ]
})
export class ComponentsModule {}
